import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './styles/index.scss'
import './element/index'
import '@/assets/icons'
import './utils/directive'
//  main.js中引入css
import 'video.js/dist/video-js.css'

/*
 * 语言设置1是英文，2是中文
 * 1:判端是否在页面选择语言缓存了
 * 2:通过浏览器语言判断
 */


Vue.config.productionTip = false

// rem计算
function setRemPc() {
  const num = window.innerWidth < 768 ? 375:1820;//调大尺寸把1920改成1820
  const whdef = 1 / num;// 表示1920的设计图,使用1PX的默认值
  let bodyWidth = document.body.clientWidth>1820?1820: document.body.clientWidth;// 当前窗口的宽度
  if (navigator.userAgent.match(/iPad/i)) {
    bodyWidth = Math.min(bodyWidth, 1280); // 假设 iPad 最大宽度为 1024px，根据实际情况调整
  }

  const rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = `${rem}px`;
}
import { version } from '../package.json';
const VUE_APP_VERSION = version
const vers = window.localStorage.getItem("Version");
if (VUE_APP_VERSION != vers) {
  localStorage.clear();
  sessionStorage.clear();
  window.localStorage.setItem("Version", VUE_APP_VERSION);
  window.location.reload()
}
new Vue({
  store,
  router,
  render: h => h(App),
  created() {
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
    setRemPc();
    window.addEventListener('resize', setRemPc);// 浏览器窗口大小改变时调用rem换算方法
  },
}).$mount('#app')
