import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 1是英文，2是中文
    languageTypeActive: '1',

  },
  mutations: {
    setLang(state,val){
      state.languageTypeActive = val
    }
  },
  actions: {
    changeLanguage({ commit }, language) {
      commit('setLanguage', language)
    }
  },
  modules: {
  }
})
