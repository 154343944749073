import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/terms_of_use.html',
    redirect: '/terms_of_use',
  },
  {
    path: '/privacy_policy.html',
    redirect: '/privacy_policy',
  },
  {
    path: '/community_guidelines.html',
    redirect: '/community_guidelines',
  },
  {
    path: '/mobileterms_of_use.html',
    redirect: '/mobileterms_of_use',
  },
  {
    path: '/mobileprivacy_policy.html',
    redirect: '/mobileprivacy_policy',
  },
  {
    path: '/mobilecommunity_guidelines.html',
    redirect: '/mobilecommunity_guidelines',
  },
  // PC端路由
  {
    path: '/404',
    component: () => import('@/views/pc/404.vue'),
    meta: {
      title: '可搭吖 | 访问出错啦',
      customRoute: '404'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/pc/home.vue'),
    meta: {
      title: '可搭吖 | 一款专属于女孩的搭子APP',
      customRoute: 'home'
    }
  },
  {
    path: '/connectUs',
    name: 'ConnectUs',
    component: () => import('../views/pc/connectUs.vue'),
    meta: {
      title: '可搭吖 | 联系我们',
      customRoute: 'connectUs'
    }
  },
  {
    path: '/terms_of_use',
    name: 'Agreement',
    component: () => import('../views/pc/agreement.vue'),
    meta: {
      title: '可搭吖 | 用户协议',
      customRoute: 'terms_of_use'
    }
  },
  {
    path: '/privacy_policy',
    name: 'Policy',
    component: () => import('../views/pc/policy.vue'),
    meta: {
      title: '可搭吖 | 隐私政策',
      customRoute: 'privacy_policy'
    }
  },
  {
    path: '/community_guidelines',
    name: 'Standard',
    component: () => import('../views/pc/standard.vue'),
    meta: {
      title: '可搭吖 | 社区规范',
      customRoute: 'community_guidelines'
    }
  },
  // H5端路由
  {
    path: '/mobile404',
    component: () => import('@/views/mobile/404.vue'),
    meta: {
      title: '可搭吖 | 访问出错啦',
      customRoute: '404'
    }
  },
  {
    path: '/mobilehome',
    name: 'MobileHome',
    component: () => import('../views/mobile/home.vue'),
    meta: {
      title: '可搭吖 | 一款专属于女孩的搭子APP',
      customRoute: 'home'
    }
  },
  {
    path: '/mobileconnectUs',
    name: 'MobileConnectUs',
    component: () => import('../views/mobile/contactUs.vue'),
    meta: {
      title: '可搭吖 | 联系我们',
      customRoute: 'connectUs'
    }
  },
  {
    path: '/mobileterms_of_use',
    name: 'MobileAgreement',
    component: () => import('../views/mobile/agreement.vue'),
    meta: {
      title: '可搭吖 | 用户协议',
      customRoute: 'terms_of_use'
    }
  },
  {
    path: '/mobileprivacy_policy',
    name: 'MobilePolicy',
    component: () => import('../views/mobile/policy.vue'),
    meta: {
      title: '可搭吖 | 隐私政策',
      customRoute: 'privacy_policy'
    }
  },
  {
    path: '/mobilecommunity_guidelines',
    name: 'MobileStandard',
    component: () => import('../views/mobile/standard.vue'),
    meta: {
      title: '可搭吖 | 社区规范',
      customRoute: 'community_guidelines'
    }
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name) {
      return { x: 0, y: 0 }
    } else {
      return savedPosition || { x: 0, y: 0 };
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  window.scrollTo(0, 0);
  const isMobile = window.innerWidth < 768 ? true : false
  if (isMobile && !to.path.startsWith('/mobile')) {
    // 手机设备，并且目标路由没有/mobile前缀
    const mobilePath = '/mobile' + to.meta.customRoute;
    next(mobilePath);
  } else if (!isMobile && to.path.startsWith('/mobile')) {
    // 不是手机设备，并且目标路由包含/mobile前缀
    const mobilePath = '/' + to.meta.customRoute;
    next(mobilePath);
  } else {
    next();
  }
})
export default router
