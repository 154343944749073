<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      isMobile: window.innerWidth < 768 ? true : (this.isAndroidTablet() ? true :false),
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize); // 监听窗口大小变化
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768 ? true : this.isAndroidTablet()?true :false
    },
    isAndroidTablet(){
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      let isAndroid = /android/i.test(userAgent.toLowerCase());
      let isMobile = /mobile/i.test(userAgent.toLowerCase());
      // 排除安卓手机和iOS设备
      if (isMobile && isXiaomi ) {
        return true;
      }else if(isMobile &&isAndroid){
        return true;
      }else{
        return false;
      }
    }
  }
}
</script>

<style lang="scss">
#app {}
</style>
